import VMasker from 'vanilla-masker'

const formatPhone = (phone = '') => {
  if (phone.length > 14) {
    return VMasker.toPattern(phone, '(99) 99999-9999')
  }

  return VMasker.toPattern(phone, '(99) 9999-9999')
}

export default formatPhone
