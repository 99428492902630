const banks = [
  { value: '341', label: 'ITAÚ UNIBANCO S.A.' },
  { value: '001', label: 'BANCO DO BRASIL S.A.' },
  { value: '237', label: 'BRADESCO S.A.' },
  { value: '104', label: 'CAIXA ECONÔMICA FEDERAL S.A.' },
  { value: '033', label: 'SANTANDER S.A.' },
  { value: '422', label: 'SAFRA S.A.' },
  { value: '655', label: 'BANCO VOTORANTIM S.A.' },
  { value: '745', label: 'CITIBANK S.A.' },
  { value: '004', label: 'BANCO DO NORDESTE DO BRASIL S.A.' },
  { value: '269', label: 'HSBC S.A.' },
  { value: '260', label: 'Nu Pagamentos S.A.' },

  { value: '654', label: 'BANCO A.J. RENNER S.A.' },
  { value: '246', label: 'BANCO ABC BRASIL S.A.' },
  { value: '075', label: 'BANCO ABN AMRO S.A.' },
  { value: '025', label: 'BANCO ALFA S.A.' },
  { value: '641', label: 'BANCO ALVORADA S.A.' },
  { value: '213', label: 'BANCO ARBI S.A.' },
  { value: '019', label: 'BANCO AZTECA DO BRASIL S.A.' },
  { value: '024', label: 'BANCO BANDEPE S.A.' },
  { value: '740', label: 'BANCO BARCLAYS S.A.' },
  { value: '107', label: 'BANCO BBM S/A' },
  { value: '096', label: 'BANCO BM&FBOVESPA S.A.' },
  { value: '318', label: 'BANCO BMG S.A.' },
  { value: '752', label: 'BANCO BNP PARIBAS BRASIL S.A.' },
  { value: '248', label: 'BANCO BOAVISTA INTERATLANTICO S.A.' },
  { value: '218', label: 'BANCO BONSUCESSO S.A.' },
  { value: '063', label: 'BANCO BRADESCARD S.A.' },
  { value: '036', label: 'BANCO BRADESCO BBI S.A.' },
  { value: '122', label: 'BANCO BRADESCO BERJ S.A.' },
  { value: '204', label: 'BANCO BRADESCO CARTÕES S.A.' },
  { value: '394', label: 'BANCO BRADESCO FINANCIAMENTOS S.A.' },
  { value: '208', label: 'BANCO BTG PACTUAL S.A.' },
  { value: '263', label: 'BANCO CACIQUE S.A.' },
  { value: '412', label: 'BANCO CAPITAL S.A.' },
  { value: '040', label: 'BANCO CARGILL S.A.' },
  { value: '266', label: 'BANCO CEDULA S.A.' },
  { value: '739', label: 'BANCO CETELEM S.A.' },
  { value: '233', label: 'BANCO CIFRA S.A.' },
  { value: '241', label: 'BANCO CLASSICO S.A.' },
  { value: 'M19', label: 'BANCO CNH INDUSTRIAL CAPITAL S.A.' },
  { value: '095', label: 'BANCO CONFIDENCE DE CÂMBIO S.A.' },
  { value: '756', label: 'BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB' },
  { value: '748', label: 'BANCO COOPERATIVO SICREDI S.A.' },
  { value: '222', label: 'BANCO CRÉDIT AGRICOLE BRASIL S.A.' },
  { value: '505', label: 'BANCO CREDIT SUISSE (BRASIL) S.A.' },
  { value: '003', label: 'BANCO DA AMAZONIA S.A.' },
  { value: '083', label: 'BANCO DA CHINA BRASIL S.A.' },
  { value: '707', label: 'BANCO DAYCOVAL S.A.' },
  { value: '300', label: 'BANCO DE LA NACION ARGENTINA' },
  { value: '495', label: 'BANCO DE LA PROVINCIA DE BUENOS AIRES' },
  { value: '494', label: 'BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY' },
  { value: 'M06', label: 'BANCO DE LAGE LANDEN BRASIL S.A.' },
  { value: '456', label: 'BANCO DE TOKYO-MITSUBISHI UFJ BRASIL S.A.' },
  { value: '047', label: 'BANCO DO ESTADO DE SERGIPE S.A.' },
  { value: '037', label: 'BANCO DO ESTADO DO PARÁ S.A.' },
  { value: '041', label: 'BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.' },
  { value: '265', label: 'BANCO FATOR S.A.' },
  { value: '224', label: 'BANCO FIBRA S.A.' },
  { value: '626', label: 'BANCO FICSA S.A.' },
  { value: 'M18', label: 'BANCO FORD S.A.' },
  { value: '121', label: 'BANCO GERADOR S.A.' },
  { value: 'M07', label: 'BANCO GMAC S.A.' },
  { value: '612', label: 'BANCO GUANABARA S.A.' },
  { value: 'M22', label: 'BANCO HONDA S.A.' },
  { value: 'M11', label: 'BANCO IBM S.A.' },
  { value: '604', label: 'BANCO INDUSTRIAL DO BRASIL S.A.' },
  { value: '320', label: 'BANCO INDUSTRIAL E COMERCIAL S.A.' },
  { value: '653', label: 'BANCO INDUSVAL S.A.' },
  { value: '630', label: 'BANCO INTERCAP S.A.' },
  { value: '077', label: 'BANCO INTERMEDIUM S.A.' },
  { value: '249', label: 'BANCO INVESTCRED UNIBANCO S.A.' },
  { value: '184', label: 'BANCO ITAÚ BBA S.A.' },
  { value: '029', label: 'BANCO ITAÚ BMG CONSIGNADO S.A.' },
  { value: '479', label: 'BANCO ITAUBANK S.A.' },
  { value: '074', label: 'BANCO J. SAFRA S.A.' },
  { value: '376', label: 'BANCO J.P. MORGAN S.A.' },
  { value: '217', label: 'BANCO JOHN DEERE S.A.' },
  { value: '076', label: 'BANCO KDB DO BRASIL S.A.' },
  { value: '757', label: 'BANCO KEB DO BRASIL S.A.' },
  { value: '600', label: 'BANCO LUSO BRASILEIRO S.A.' },
  { value: '243', label: 'BANCO MÁXIMA S.A.' },
  { value: 'M12', label: 'BANCO MAXINVEST S.A.' },
  { value: '389', label: 'BANCO MERCANTIL DO BRASIL S.A.' },
  { value: '370', label: 'BANCO MIZUHO DO BRASIL S.A.' },
  { value: '746', label: 'BANCO MODAL S.A.' },
  { value: 'M10', label: 'BANCO MONEO S.A.' },
  { value: '066', label: 'BANCO MORGAN STANLEY S.A.' },
  { value: '079', label: 'BANCO ORIGINAL DO AGRONEGÓCIO S.A.' },
  { value: '212', label: 'BANCO ORIGINAL S.A.' },
  { value: 'M17', label: 'BANCO OURINVEST S.A.' },
  { value: '623', label: 'BANCO PAN S.A.' },
  { value: '611', label: 'BANCO PAULISTA S.A.' },
  { value: '613', label: 'BANCO PECUNIA S.A.' },
  { value: '094', label: 'BANCO PETRA S.A.' },
  { value: '643', label: 'BANCO PINE S.A.' },
  { value: '735', label: 'BANCO POTTENCIAL S.A.' },
  { value: 'M24', label: 'BANCO PSA FINANCE BRASIL S.A.' },
  { value: '747', label: 'BANCO RABOBANK INTERNATIONAL BRASIL S.A.' },
  { value: '088', label: 'BANCO RANDON S.A.' },
  { value: '633', label: 'BANCO RENDIMENTO S.A.' },
  { value: '741', label: 'BANCO RIBEIRAO PRETO S.A.' },
  { value: '120', label: 'BANCO RODOBENS S.A.' },
  { value: '743', label: 'BANCO SEMEAR S.A.' },
  { value: '754', label: 'BANCO SISTEMA S.A.' },
  { value: '366', label: 'BANCO SOCIETE GENERALE BRASIL S.A.' },
  { value: '637', label: 'BANCO SOFISA S.A.' },
  { value: '464', label: 'BANCO SUMITOMO MITSUI BRASILEIRO S.A.' },
  { value: '082', label: 'BANCO TOPÁZIO S.A.' },
  { value: 'M20', label: 'BANCO TOYOTA DO BRASIL S.A.' },
  { value: '634', label: 'BANCO TRIANGULO S.A.' },
  { value: '018', label: 'BANCO TRICURY S.A.' },
  { value: 'M14', label: 'BANCO VOLKSWAGEN S.A.' },
  { value: 'M23', label: 'BANCO VOLVO BRASIL S.A.' },
  { value: '610', label: 'BANCO VR S.A.' },
  { value: '119', label: 'BANCO WESTERN UNION DO BRASIL S.A.' },
  { value: '124', label: 'BANCO WOORI BANK DO BRASIL S.A.' },
  { value: '021', label: 'BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO' },
  {
    value: '719',
    label: 'BANIF - BANCO INTERNACIONAL DO FUNCHAL (BRASIL), S.A.',
  },
  { value: '755', label: 'BANK OF AMERICA MERRILL LYNCH BANCO MÚLTIPLO S.A.' },
  { value: '081', label: 'BBN BANCO BRASILEIRO DE NEGÓCIOS S.A.' },
  { value: '250', label: 'BCV - BANCO DE CRÉDITO E VAREJO S/A' },
  { value: '017', label: 'BNY MELLON BANCO S.A.' },
  { value: '069', label: 'BPN BRASIL BANCO MÚLTIPLO S.A.' },
  { value: '125', label: 'BRASIL PLURAL S.A. BANCO MÚLTIPLO' },
  { value: '070', label: 'BRB - BANCO DE BRASILIA S.A.' },
  { value: '477', label: 'CITIBANK N.A.' },
  { value: '487', label: 'DEUTSCHE BANK S.A. - BANCO ALEMAO' },
  { value: '064', label: 'GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.' },
  { value: '062', label: 'HIPERCARD BANCO MÚLTIPLO S.A.' },
  { value: '132', label: 'ICBC DO BRASIL BANCO MÚLTIPLO S.A.' },
  { value: '492', label: 'ING BANK N.V.' },
  { value: '652', label: 'ITAÚ UNIBANCO HOLDING S.A.' },
  { value: '488', label: 'JPMORGAN CHASE BANK, NATIONAL ASSOCIATION' },
  { value: '014', label: 'NATIXIS BRASIL S.A. BANCO MÚLTIPLO' },
  { value: '753', label: 'NOVO BANCO CONTINENTAL S.A. - BANCO MÚLTIPLO' },
  { value: '254', label: 'PARANÁ BANCO S.A.' },
  { value: '751', label: 'SCOTIABANK BRASIL S.A. BANCO MÚLTIPLO' },
]

export default banks
