const statusLabel = status => {
  switch (status) {
    case 'paid':
      return 'Pagamento Efetuado'
    case 'awaiting_approval':
      return 'Aguardando Aprovação'
    case 'canceled':
      return 'Cancelado'
    case 'reversed':
      return 'Estornado'
    case 'partially_paid':
      return 'Pagamento Parcial'
    default:
      return 'Aguardando Pagamento'
  }
}

export default statusLabel
