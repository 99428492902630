import constants from './../../constants'

const description = ({ entity, actionMethod }) => {
  const email = constants.emails.EMAILS.find(
    currentEmail =>
      currentEmail.entity === entity &&
      currentEmail.actionMethod === actionMethod,
  )

  return email ? email.description : null
}

export default description
