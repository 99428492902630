import React from 'react'
import useForm from 'react-hook-form'

import { makeStyles } from '@material-ui/styles'
import { InputCurrency } from 'components/Form'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { KeyboardDatePicker } from '@material-ui/pickers'

import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    width: 320,
  },
  filterField: {
    marginTop: theme.spacing(2),
  },
}))

const Filters = ({ filter, children }) => {
  const classes = useStyles()
  const [values, setValues] = React.useState(filter.filters)
  const { register, handleSubmit } = useForm()

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    filter.setFilters(values)
    filter.setDrawerOpen(false)
  }

  const clearFilters = () => {
    children.map(field => {
      return setValues({ [field.props.name]: '' })
    })
    filter.setFilters('')
    filter.setDrawerOpen(false)
  }

  return (
    <Drawer
      anchor="right"
      open={filter.drawerOpen}
      onClose={() => filter.setDrawerOpen(false)}
    >
      <Box px={2} py={4} className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4" gutterBottom>
            Filtros
          </Typography>

          {children.map(
            (field, index) =>
              (field.props.textfieldinput && (
                <TextField
                  variant="outlined"
                  SelectProps={{ native: true }}
                  margin="dense"
                  className={classes.filterField}
                  fullWidth
                  value={values[field.props.name] || ''}
                  select={field.type === 'select'}
                  onChange={handleChange}
                  {...field.props}
                  inputProps={{
                    ref: register,
                    ...field.props.inputProps,
                  }}
                  key={index}
                ></TextField>
              )) ||
              (field.props.currencyinput && (
                <InputCurrency
                  SelectProps={{ native: true }}
                  margin="dense"
                  variant="outlined"
                  className={classes.filterField}
                  fullWidth
                  value={values[field.props.name] || 0}
                  onChange={handleChange}
                  {...field.props}
                  inputProps={{
                    ref: register,
                    ...field.props.inputProps,
                  }}
                  key={index}
                ></InputCurrency>
              )) ||
              (field.props.datepickerinput && (
                <KeyboardDatePicker
                  margin="dense"
                  variant="outlined"
                  className={classes.filterField}
                  fullWidth
                  format="dd/MM/yyyy"
                  value={values[field.props.name] || null}
                  onChange={e => {
                    setValues({
                      ...values,
                      [field.props.name]: moment(e)
                        .utc(false)
                        .format(),
                    })
                  }}
                  {...field.props}
                  inputProps={{
                    ref: register,
                    ...field.props.inputProps,
                  }}
                  key={index}
                ></KeyboardDatePicker>
              )),
          )}
          <Button
            className={classes.filterField}
            variant="contained"
            color="default"
            fullWidth
            type="button"
            onClick={clearFilters}
          >
            Limpar Filtros
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.filterField}
            fullWidth
          >
            Filtrar
          </Button>
        </form>
      </Box>
    </Drawer>
  )
}

export default Filters
