import api from '../../api'

const authApi = api.create( 'yapay-login' )

const getUserPermissions = async userId => {
  const res = await authApi.get(`/users/${userId}`)

  let user = {
    name: res.data.name,
    email: res.data.email,
    photo: res.data.photo
  }

  return user
}

const updatePassword = (id, data) => {
  return authApi.put(`users/${id}`, data)
}

const resetPassword = (resetPasswordToken, password, passwordConfirmation) => {
  return authApi.put('/users/password', {
    user: {
      resetPasswordToken,
      password,
      passwordConfirmation,
    },
  })
}


export default {
  getUserPermissions,
  updatePassword,
  resetPassword
}
