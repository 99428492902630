const CONTRACT_OVERDUE = 1
const CONTRACT_CANCEL_OVERDUE = 2
const CONTRACT_CANCEL_PENDING = 3
const INVOICE_INTERVAL = 4
const PAYMENT_MAX_SPLIT = 5
const PAYMENT_DAYS_TO_GENERATE = 6
const URL_NOTIFICATION_DEFAULT_INVOICES = 9
const URL_NOTIFICATION_DEFAULT_CONTRACTS = 10
const YAPAY_TOKEN_ACCOUNT = 16
const INVOICES_GENERATION_ON_OVERDUE = 23
const BILLET_METHOD = 7
const REPROCESS_BILLET_METHOD = 13
const CARD_METHOD = 8
const REPROCESS_CARD_METHOD = 14
const TRANSFER_METHOD = 15
const BALANCE_METHOD = 17
const DAYS_TO_GENERATE_NFE = 11
const GENERATE_NFE = 12
const NFEIO_API_KEY = 18
const NFEIO_COMPANY_ID = 19
const NFEIO_SERVICE_CODE = 20
const SIMPLES_NACIONAL_NFE = 24


export default {
  INVOICE_INTERVAL,
  CONTRACT_OVERDUE,
  CONTRACT_CANCEL_OVERDUE,
  CONTRACT_CANCEL_PENDING,
  PAYMENT_MAX_SPLIT,
  PAYMENT_DAYS_TO_GENERATE,
  URL_NOTIFICATION_DEFAULT_INVOICES,
  URL_NOTIFICATION_DEFAULT_CONTRACTS,
  YAPAY_TOKEN_ACCOUNT,
  INVOICES_GENERATION_ON_OVERDUE,
  BILLET_METHOD,
  REPROCESS_BILLET_METHOD,
  CARD_METHOD,
  REPROCESS_CARD_METHOD,
  TRANSFER_METHOD,
  BALANCE_METHOD,
  DAYS_TO_GENERATE_NFE,
  SIMPLES_NACIONAL_NFE,
  GENERATE_NFE,
  NFEIO_API_KEY,
  NFEIO_COMPANY_ID,
  NFEIO_SERVICE_CODE,
}
