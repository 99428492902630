import React from 'react'
import { Offline } from 'react-detect-offline'
import logo from 'images/yapay.svg'

import WarningIcon from '@material-ui/icons/Warning'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { makeStyles } from '@material-ui/styles'
import { amber } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  root: {
    transition: 'all 300ms ease',
    transitionDelay: 1500,
    position: 'relative',
    zIndex: 9999,
  },
  rootLoaded: {
    opacity: 0,
    visibility: 'hidden',
    zIndex: -1,
  },
  bg: {
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    height: '100%',
    width: '100%',
    transform: 'translate(0,0)',
    transition: 'all 500ms ease',
    transitionDelay: 1000,
  },
  bgLoaded: {
    transform: 'translate(0,calc(-100% + 64px))',
  },
  logo: {
    width: 130,
    height: 36,
    position: 'fixed',
    left: 0,
    top: 0,
    transition: 'all 1s ease',
    transform: 'translate(calc(50vw - 70px), calc(50vh - 36px)) scale(1.5)',
    [theme.breakpoints.down('sm')]: {
      width: 90,
      height: 25,
      transform: 'translate(calc(50vw - 45px), calc(50vh - 12px)) scale(1.5)',
    },
  },
  logoLoaded: {
    transform: 'translate(24px, 14px) scale(1)',
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(24px, 19px) scale(1)',
    },
    [theme.breakpoints.down('xs')]: {
      transform: 'translate(16px, 16px) scale(1)',
    },
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
  icon: {
    fontSize: 20,
  },
  warning: {
    backgroundColor: amber[700],
  },
}))

const ScreenLoading = ({ loaded, children }) => {
  const classes = useStyles()

  return (
    <>
      <div className={`${classes.root} ${loaded && classes.rootLoaded}`}>
        <div className={`${classes.bg} ${loaded && classes.bgLoaded}`} />
        <figure className={`${classes.logo} ${loaded && classes.logoLoaded}`}>
          <img alt="Logo" src={logo} width="100%" />
        </figure>
        <Offline>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={true}
          >
            <SnackbarContent
              className={classes.warning}
              message={
                <span className={classes.message}>
                  <WarningIcon className={classes.iconVariant} />
                  Você está sem internet
                </span>
              }
            />
          </Snackbar>
        </Offline>
      </div>
      {loaded && children}
    </>
  )
}

export default ScreenLoading
