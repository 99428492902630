const charges = contractPlan => {
  if (!contractPlan || contractPlan === undefined || contractPlan === null)
    return ''

  if (parseInt(contractPlan.quantity) === 0) return 'Recorrente'

  return contractPlan.quantityLaunched + '/' + contractPlan.quantity
}

export default charges
