const statusLabel = status => {
  switch (status) {
    case 'pending':
      return 'Pendente'
    case 'active':
      return 'Ativo'
    case 'inactive':
      return 'Inativo'
    case 'overdue':
      return 'Inadimplente'
    case 'canceled':
      return 'Cancelado'
    default:
      return 'Finalizado'
  }
}

export default statusLabel
