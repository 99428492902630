const charges = contractPlanEntry => {
  if (
    !contractPlanEntry ||
    contractPlanEntry.length === 0 ||
    contractPlanEntry === undefined
  )
    return ''

  if (parseInt(contractPlanEntry[0].quantity) === 0) return 'Recorrente'

  return (
    contractPlanEntry[0].quantityLaunched + '/' + contractPlanEntry[0].quantity
  )
}

export default charges
