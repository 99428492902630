import colorGreen from '@material-ui/core/colors/green'
import colorRed from '@material-ui/core/colors/red'
import colorYellow from '@material-ui/core/colors/yellow'

const statusColor = statusTag => {
  switch (statusTag) {
    case 'issued':
      return colorGreen[500]

    case 'created':
      return colorYellow[500]

    case 'canceled':
      return colorRed[800]

    case 'error':
      return colorRed[500]

    case 'pending_cancel':
      return colorYellow[800]

    default:
      return colorYellow[500]
  }
}

export default statusColor
