const EMAILS = [
  {
    entity: 'invoice',
    actionMethod: 'warn_before_due_date_first_option',
    title: 'Primeira notificação antes do vencimento',
    description:
      'Envio de lembrete sobre o vencimento da fatura, com as informações da data do vencimento, valor, número da fatura e link para pagamento.',
  },
  {
    entity: 'invoice',
    actionMethod: 'warn_before_due_date_second_option',
    title: 'Segunda notificação antes do vencimento',
    description:
      'Envio de lembrete sobre o vencimento da fatura, com as informações da data do vencimento, valor, número da fatura e link para pagamento.',
  },
  {
    entity: 'invoice',
    actionMethod: 'warn_before_due_date_third_option',
    title: 'Terceira notificação antes do vencimento',
    description:
      'Envio de lembrete sobre o vencimento da fatura, com as informações da data do vencimento, valor, número da fatura e link para pagamento.',
  },
  {
    entity: 'invoice',
    actionMethod: 'warn_after_due_date_first_option',
    title: 'Primeira notificação após o vencimento',
    description:
      'Envio de notificação sobre fatura vencida, com as informações da data de vencimento, valor, número da fatura e link para pagamento.',
  },
  {
    entity: 'invoice',
    actionMethod: 'warn_after_due_date_second_option',
    title: 'Segunda notificação após o vencimento',
    description:
      'Envio de notificação sobre fatura vencida, com as informações da data de vencimento, valor, número da fatura e link para pagamento.',
  },
  {
    entity: 'invoice',
    actionMethod: 'warn_after_due_date_third_option',
    title: 'Terceira notificação após o vencimento',
    description:
      'Envio de notificação sobre fatura vencida, com as informações da data de vencimento, valor, número da fatura e link para pagamento.',
  },
  {
    entity: 'invoice',
    actionMethod: 'notify_payment',
    title: 'Pagamento identificado',
    description:
      'Envio quando o pagamento da fatura foi identificado, com as informações do número da fatura, valor e data do pagamento.',
  },
  {
    entity: 'invoice',
    actionMethod: 'warn_due_date',
    title: 'Notificação no dia do vencimento',
    description:
      'Envio de uma notificação no dia do vencimento da fatura, com as informações da data de vencimento e valor.',
  },
  {
    entity: 'invoice',
    actionMethod: 'resend_email',
    title: 'Reenvio da fatura',
    description: '',
  },
  {
    entity: 'invoice',
    actionMethod: 'send_email',
    title: 'Cobrança recorrente da fatura',
    description:
      'Envio da fatura, com as informações da data de vencimento, valor, número da fatura e link para pagamento.',
  },
  {
    entity: 'invoice',
    actionMethod: 'recurring_payment_failed',
    title: 'Falha no débito automático',
    description:
      'Envio quando há falha no pagamento com débito automático, com as informações do valor, número da fatura e data do vencimento.',
  },
  {
    entity: 'contract',
    actionMethod: 'notify_creation',
    title: 'Nova assinatura',
    description:
      'Envio ao criar a assinatura, com as informações de número da assinatura e valor',
  },
  {
    entity: 'contract',
    actionMethod: 'notify_finished',
    title: 'Assinatura finalizada',
    description:
      'Envio ao finalizar a assinatura, com as informações da data de finalização.',
  },
  {
    entity: 'contract',
    actionMethod: 'notify_canceled',
    title: 'Assinatura cancelada',
    description:
      'Envio ao cancelar a assinatura, com as informações da data e motivo do cancelamento.',
  },
  {
    entity: 'contract',
    actionMethod: 'notify_overdue',
    title: 'Assinatura inadimplente',
    description:
      'Envio da notificação de inadimplência, de acordo com a configuração "Dias para definir uma assinatura como inadimplente por falta de pagamento.',
  },
  {
    entity: 'contract',
    actionMethod: 'notify_inactivation',
    title: 'Assinatura inativada',
    description:
      'Envio ao inativar a assinatura, com a informação de valor da assinatura',
  },
  {
    entity: 'contract_plan',
    actionMethod: 'notify_changes',
    title: 'Alteração nos planos da assinatura',
    description:
      'Envio ao fazer alguma alteração nos planos da assinatura, com as informações da alteração feita e nome do plano.',
  },
  {
    entity: 'customer',
    actionMethod: 'welcome',
    title: 'Novo cliente',
    description:
      'Envio do e-mail informando a criação de uma conta para o cliente.',
  },
]

const BEFORE_DUE_DATE_ACTIONS_METHODS = [
  'warn_before_due_date_first_option',
  'warn_before_due_date_second_option',
  'warn_before_due_date_third_option',
]

const AFTER_DUE_DATE_ACTIONS_METHODS = [
  'warn_after_due_date_first_option',
  'warn_after_due_date_second_option',
  'warn_after_due_date_third_option',
]

export default {
  EMAILS,
  BEFORE_DUE_DATE_ACTIONS_METHODS,
  AFTER_DUE_DATE_ACTIONS_METHODS,
}
