import React from 'react'
import { include } from 'named-urls'
import { Switch, Redirect, Route } from 'react-router-dom'

import { RouteWithLayout } from './components'
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts'

import {
  Callback as CallbackView,
  Dashboard as DashboardView,
  Forbidden as ForbiddenView,
  NotFound as NotFoundView,
  AccountMain as AccountView,
} from './views'


const Routes = () => {
  return (
    <Switch>
      <Redirect exact from={routes.root} to={routes.dashboard} />
      <RouteWithLayout
        component={DashboardView}
        layout={MainLayout}
        path={routes.dashboard}
        exact
        auth
      />
      <RouteWithLayout
        component={AccountView}
        layout={MainLayout}
        path={routes.accounts}
        exact
        auth
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path={routes.notFound}
      />
      <Route component={CallbackView} exact path={routes.auth.callback} />
      <Route component={ForbiddenView} exact path={routes.forbidden} />
      <Redirect to={routes.notFound} />
    </Switch>
  )
}

// Routes paths to consume in entire app
export const routes = {
  root: '/',
  auth: include('/auth', {
    callback: 'callback/:token',
  }),
  dashboard: '/dashboard',
  accounts: '/accounts',
  notFound: '/not-found',
  forbidden: '/forbidden',
}

export default Routes
