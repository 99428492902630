const value = contract => {
  let contractValue = 0

  if (!contract || contract === undefined || contract === null)
    return contractValue

  contract.contractPlans.forEach(contractPlan => {
    let contractPlanValue = parseFloat(contractPlan.price)
    if (
      !contractPlan.contractPlanEntries ||
      contractPlan.contractPlanEntries === undefined ||
      contractPlan.contractPlanEntries === null
    ) {
      return (contractValue += parseFloat(contractPlanValue))
    }

    contractPlan.contractPlanEntries.forEach(contractPlanEntry => {
      contractPlanValue += parseFloat(contractPlanEntry.price)
    })

    return (contractValue += contractPlanValue)
  })

  return contractValue
}

export default value
