import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import * as service from 'service'
import { getBaseURL } from 'service/env'
import { PROJECT_TAG } from 'service/constants'

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, auth, ...rest } = props

  if (auth && !service.localStorage.isAuthenticated()) {
    const signIn = getBaseURL('sign-in')
    service.localStorage.clear()
    window.location.assign(signIn + '/login/' + PROJECT_TAG)
    return null
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  )
}

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
}

export default RouteWithLayout
