const isIssued = status => {
  return status === 'issued'
}

const isCanceled = status => {
  return status === 'canceled'
}

const isFailed = status => {
  return status === 'error'
}

export default {
  isIssued,
  isCanceled,
  isFailed,
}
