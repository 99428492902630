const status = status => {
  switch (status) {
    case 1:
      return 'Ativação pendente'
    case 2:
      return 'Ativa'
    case 3:
      return 'Inativa'
    case 4:
      return 'Inadimplente'
    case 5:
      return 'Cancelada'
    default:
      return 'Finalizada'
  }
}

export default status
