import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import * as service from 'service'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  figure: {
    maxWidth: 800,
    margin: '0 auto',
  },
  image: {
    maxWidth: '100%',
  },
}))

const Forbidden = () => {
  const email = service.localStorage.getUserEmailFromToken()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        <figure className={classes.figure}>
          <img
            src="/images/forbidden.png"
            alt="Proibido"
            className={classes.image}
          />
        </figure>
        <Typography variant="h1" align="center">
          Acesso proibido | {email}
        </Typography>
      </div>
    </div>
  )
}

export default Forbidden
