/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import qs from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'

const useFilter = initialFilter => {
  const location = useLocation()
  const history = useHistory()
  const search = qs.parse(location.search)
  delete search.page

  // create an state equal the filter state, plus the existing query params
  const [filters, setFilters] = React.useState({ ...initialFilter, ...search })
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  React.useEffect(() => {
    // returns only the key with value
    const updatedFilters = Object.keys(filters)
      .filter(key => !!filters[key])
      .reduce((obj, key) => ((obj[key] = filters[key]), obj), {})

    // create a new query with only the changed fields and update the history

    history.push(location.pathname + '?' + qs.stringify(updatedFilters))
  }, [filters])
  return { filters, setFilters, drawerOpen, setDrawerOpen }
}

export default useFilter
