import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Typography, Container, Divider } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  spacer: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#ddd',
  },
}))

const Footer = props => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <Container maxWidth="lg">
      <div {...rest} className={classes.root}>
        <Divider className={classes.spacer} />
        <Typography variant="subtitle2" align="center">
          2020 &copy; Yapay Pagamentos - Versão 2020
        </Typography>
      </div>
    </Container>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
