import invoices from './invoices'
import people from './people'
import taxInvoices from './taxInvoices'
import brazilianStates from './brazilianStates'
import chartjs from './chartjs'
import contracts from './contracts'
import contractPlans from './contractPlans'
import contractPlanEntries from './contractPlanEntries'
import contractTaxes from './contractTaxes'
import historyContractStatuses from './historyContractStatuses'
import entries from './entries'
import currency from './currency'
import formatDate from './formatDate'
import getInitials from './getInitials'
import invoiceItems from './invoiceItems'
import orders from './orders'
import archives from './archives'
import settings from './settings'
import taxInvoiceSellers from './taxInvoiceSellers'
import sleep from './sleep'
import emails from './emails'

export default {
  invoices,
  people,
  taxInvoices,
  brazilianStates,
  chartjs,
  contracts,
  contractPlans,
  contractPlanEntries,
  contractTaxes,
  historyContractStatuses,
  entries,
  currency,
  formatDate,
  getInitials,
  invoiceItems,
  orders,
  archives,
  settings,
  taxInvoiceSellers,
  sleep,
  emails,
}