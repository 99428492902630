import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Container, TablePagination, Box, Paper} from '@material-ui/core'
import { AccountsTable } from './components'
import { Filters, TableHeader, ContentHeader } from 'components'
import useFetch from 'hooks/useFetch'
import usePagination from 'hooks/usePagination'
import useFilter from 'hooks/useFilter'
import * as service from 'service'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
}))

const Account = () => {
  const classes = useStyles()
  const filter = useFilter()

  const {
    perPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination(20)

  const { response, isLoading } = useFetch(
    service.recurrence.accounts.paginate,
      { perPage, page,  ...filter.filters },
      [page, perPage, filter.filters],
    )
        
    return (
      <Container>

      <ContentHeader title="Contas" subtitle="Listagem"></ContentHeader>

      <Paper>
        <div className={classes.root}>
          <Grid container spacing={4}>

          <Grid item lg={12} md={12} xl={12} xs={12}>
            <TableHeader setDrawerOpen={filter.setDrawerOpen} />
            <AccountsTable accounts={response && response.data.data } loading={isLoading} perPage={perPage}></AccountsTable>
          </Grid>
        </Grid>
       </div>

      <Box px={2} display="flex" justifyContent="flex-end">
          {response ? (
            <TablePagination
              component="div"
              count={response.data.pagination.totalCount}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
              labelRowsPerPage={'Por página'}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          ) : (
            <Box py="11px">
              <Skeleton variant="rect" width={200} height={30} />
            </Box>
          )}
        </Box>

        </Paper>

        <Filters filter={filter}>
          <input textfieldinput="true" label="Nome" name="name" />
          <input textfieldinput="true" label="Email" name="email" />
          <input textfieldinput="true" label="Dominio" name="subdomain" />          
        </Filters>
      </Container>
      )
    }
    
    export default Account
    