import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  }
}))

const ContentHeader = ({ title, subtitle, children }) => {
  const classes = useStyles()
  
  return (<Box
    mb={2}
    display="flex"
    className={classes.root}
    justifyContent="space-between"
    alignItems="flex-end"
    >
      <Box>
        <Typography variant="overline">{subtitle}</Typography>
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box>{children}</Box>
    </Box>)
  }
  
  export default ContentHeader
  