import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    textAlign: 'center',
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  },
}))

const NotFound = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">404: Página não encontrada</Typography>
            <Typography variant="subtitle2">
              Você também tentou algum caminho obscuro ou veio aqui por engano.
              Seja qual for, tente usar a navegação
            </Typography>
            <img
              alt="Under development"
              className={classes.image}
              src="/images/404.svg"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default NotFound
