import kind from './kind'
import formatCep from './formatCep'
import formatCpf from './formatCpf'
import formatCnpj from './formatCnpj'
import formatPhone from './formatPhone'
import convertPhoneApi from './convertPhoneApi'
import parsePhoneApi from './parsePhoneApi'
import isCompany from './isCompany'

export default {
  kind,
  formatCep,
  formatCpf,
  formatCnpj,
  formatPhone,
  convertPhoneApi,
  parsePhoneApi,
  isCompany,
}
