const isAwaitingPayment = status => {
  return status === 'awaiting_payment'
}

const isPaid = status => {
  return status === 'paid'
}

const isCanceled = status => {
  return status === 'canceled'
}

const isReversed = status => {
  return status === 'reversed'
}

const isPartiallyPaid = status => {
  return status === 'partially_paid'
}

export default {
  isAwaitingPayment,
  isPaid,
  isCanceled,
  isReversed,
  isPartiallyPaid,
}
