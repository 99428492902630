import moment from 'moment'

export default function(date) {
  if (!date) {
    return null
  }

  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  return moment(date)
}
