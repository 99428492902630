const convertPhoneApi = (phone = '') => {
  return {
    areaCode: phone.substring(1, 3).trim(),
    number: phone
      .substring(5)
      .replace(/-/g, '')
      .trim(),
  }
}

export default convertPhoneApi
