const statusColor = status => {
  switch (status) {
    case 'awaiting_payment':
      return '#FFC300'

    case 'paid':
      return '#21C712'

    case 'reversed':
      return '#8549BA'

    case 'canceled':
      return '#D33131'

    case 'partially_paid':
      return '#14D4F4'

    default:
      return '#2E7EE7'
  }
}

export default statusColor
