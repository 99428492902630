import React from 'react'

import SnackbarContext from '../context/SnackbarContext'

const useSnackbar = () => {
  const snackbarContext = React.useContext(SnackbarContext)

  if (snackbarContext === undefined) {
    throw new Error(
      'useSnackbar must be used within a SnackbarContext.Provider',
    )
  }

  return snackbarContext.snackbar
}

export default useSnackbar
