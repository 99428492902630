import React from 'react'
import { Redirect } from 'react-router-dom'
import { ScreenLoading } from 'components'

import * as service from 'service'
import { routes } from 'Routes'

const Callback = ({ match }) => {
  const [shouldRedirect, setRedirect] = React.useState(false)

  React.useEffect(() => {
    service.localStorage.login(match.params.token)

    setTimeout(() => {
      setRedirect(true)
    }, 3000)
  }, [match.params.token])

  if (shouldRedirect) {
    return <Redirect to={routes.root} />
  }

  return <ScreenLoading />
}

export default Callback
