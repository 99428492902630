const filterContractTaxes = (taxes, contractTaxes) =>
  taxes.filter(
    tax =>
      tax.active ||
      contractTaxes.some(
        contractTax => contractTax.taxId === tax.id && contractTax.active,
      ),
  )

export default filterContractTaxes
