import moment from 'moment'

const period = invoiceItem => {
  let noPeriod = ' - '

  if (!invoiceItem || !invoiceItem.from || !invoiceItem.till) return noPeriod

  return `${moment(invoiceItem.from)
    .utc(false)
    .format('DD/MM/YYYY')} - ${moment(invoiceItem.till)
    .utc(false)
    .format('DD/MM/YYYY')}`
}

export default period
