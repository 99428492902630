import constants from './../../constants'

const title = ({ entity, actionMethod }) => {
  const email = constants.emails.EMAILS.find(
    currentEmail =>
      currentEmail.entity === entity &&
      currentEmail.actionMethod === actionMethod,
  )

  return email ? email.title : null
}

export default title
