import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import getInitials from 'helpers/getInitials'
import UserContext from 'context/UserContext'

import { makeStyles } from '@material-ui/styles'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { getBaseURL } from 'service/env'

import * as service from 'service'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 80,
    height: 80,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}))

const Profile = props => {
  const { user } = React.useContext(UserContext)
  const classes = useStyles()
  const { className, ...rest } = props

  const signInBaseURL = getBaseURL('sign-in')
  const profileId = service.localStorage.getUserIdFromToken()
  let profileURL = `${signInBaseURL}/profile/${profileId}`

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Tooltip
        title="Alterar Foto"
        aria-label="Alterar Foto"
        placement="right-start"
      >
        <a href={profileURL} target="_blank" rel="noopener noreferrer">
          <Avatar alt="Person" src={user.photo} className={classes.avatar}>
            {getInitials(user.name)}
          </Avatar>
        </a>
      </Tooltip>
      <Typography className={classes.name} variant="h4" align="center">
        {user.name}
      </Typography>
      <Typography variant="body2">{user.email}</Typography>
    </div>
  )
}

Profile.propTypes = {
  className: PropTypes.string,
}

export default Profile
