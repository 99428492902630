import React from 'react'

import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import AttachMoney from '@material-ui/icons/AttachMoney'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Hidden from '@material-ui/core/Hidden'

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    background: '#21C712',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  money: {
    color: '#fff',
    marginLeft: theme.spacing(-1),
  },
  title: {
    marginLeft: theme.spacing(2),
    color: '#fff',
    textTransform: 'uppercase',
    fontWeight: '600',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },

  content: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: theme.spacing(-3),
    },
  },
  box: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  loading: {
    background: 'rgba(255,255,255,.85)',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    width: '100%',
  },
  bold: {
    fontWeight: '600',
  },
}))

const Plan = ({ plan }) => {
  const classes = useStyles()

  return (
    <>
      <Hidden smUp>
        <Typography
          variant="h5"
          gutterBottom
          color="primary"
          className={classes.bold}
        >
          Meu plano
        </Typography>
      </Hidden>
      <Card>
        <Box className={classes.headerWrapper}>
          <AttachMoney className={classes.money} />
          <Typography variant="h5" className={classes.title}>
            {plan.name}
          </Typography>
        </Box>
        <CardContent className={classes.content}>
          <Box className={classes.box}>
            <Typography variant="subtitle2" className={classes.bold}>
              Débito
            </Typography>
            <Typography variant="subtitle2">
              Plano de recebimento: 1 dia útil
            </Typography>
            <Typography variant="subtitle2">Taxa: {plan.debit}%</Typography>
          </Box>
          <Box className={classes.box}>
            <Typography variant="subtitle2" className={classes.bold}>
              Crédito
            </Typography>
            <Typography variant="subtitle2">
              Plano de recebimento: {plan.offsetDays}{' '}
              {plan.offsetDays >= 30
                ? 'dias corridos'
                : plan.offsetDays === 1
                ? 'dia útil'
                : 'dias úteis'}
            </Typography>
            <Typography variant="subtitle2">
              Taxa à vista: {plan.demandCredit}%
            </Typography>
            <Typography variant="subtitle2">
              Taxa parcelado: {plan.installmentCredit}%
            </Typography>
            <Typography variant="subtitle2">
              Taxa por parcela: {plan.tax}%
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </>
  )
}

export default Plan
