import React from 'react'
import PropTypes from 'prop-types'
import useSnackbar from 'hooks/useSnackbar'
import helpers from 'helpers'

import { makeStyles } from '@material-ui/styles'
import { Box, Divider, IconButton, CircularProgress } from '@material-ui/core'
import { FilterList as FilterIcon } from '@material-ui/icons'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import Tooltip from '@material-ui/core/Tooltip'

import styles from './styles'

const useStyles = makeStyles(styles)

const TableHeader = ({
  setDrawerOpen,
  downloadable,
  fnDownload,
  paramsDownload,
  fileName,
}) => {
  const [pageLoader, setPageLoader] = React.useState(false)
  const snackbar = useSnackbar()
  const classes = useStyles()

  const handleClickDownload = () => {
    try {
      fnDownload(paramsDownload)
        .then(response => {
          if (response.data) {
            return helpers.archives.downloadCsv(response.data, fileName)
          }
          snackbar.open({
            message: 'Será enviado o relatório para o email do usuário.',
            variant: 'success',
          })
        })
        .finally(() => {
          setPageLoader(false)
        })
    } catch {
      setPageLoader(false)
      snackbar.open({
        message: 'Falha ao tentar exportar!',
        variant: 'error',
      })
    }
  }

  return (
    <div>
      {pageLoader ? (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      ) : (
        <Box className={classes.box}>
          <Tooltip title="Filtros">
            <IconButton onClick={() => setDrawerOpen(true)}>
              <FilterIcon />
            </IconButton>
          </Tooltip>
          {downloadable && (
            <Tooltip title="Download CSV">
              <IconButton onClick={handleClickDownload}>
                <SaveAltIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
      <Divider />
    </div>
  )
}

TableHeader.propTypes = {
  setDrawerOpen: PropTypes.func,
}

TableHeader.defaultProps = {
  setDrawerOpen: () => {},
}

export default TableHeader
