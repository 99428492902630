import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { routes } from 'Routes'

import { makeStyles } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'

import DashboardIcon from '@material-ui/icons/InsertChartOutlined'
import SettingsIcon from '@material-ui/icons/AccountCircleOutlined'

import { Profile, SidebarNav } from './components'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))


const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props
  const classes = useStyles()


  const pages = [
    {
      title: 'Visão geral',
      href: routes.dashboard,
      icon: <DashboardIcon />,
    },
    {
      title: 'Contas',
      href: `${routes.accounts}`,
      icon: <SettingsIcon />,
    }
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} onClose={onClose} />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}

export default Sidebar
