const normalizeSend = contractPlanEntries => {
  return contractPlanEntries
    .filter(contractPlan => contractPlan.quantity !== '')
    .map(contractPlanEntry => ({
      ...contractPlanEntry,
      quantity: parseInt(contractPlanEntry.quantity),
      price: parseFloat(contractPlanEntry.price),
    }))
}

export default normalizeSend
