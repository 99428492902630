import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import UserContext from 'context/UserContext'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Sidebar, Topbar, Footer } from './components'
import { ScreenLoading } from 'components'
import * as service from 'service'
import SnackbarProvider from 'providers/SnackbarProvider'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: '100%',
  },
}))

const Main = props => {
  const classes = useStyles()
  const theme = useTheme()
  const { children } = props
  const [openSidebar, setOpenSidebar] = useState(false)
  const [shouldRedirect, setRedirect] = React.useState(false)
  const [user, setUser] = React.useState(null)
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  useEffect(() => {
    const loadUserAndStoreData = async () => {
      const userId = service.localStorage.getUserIdFromToken()
      let userData = await service.login.getUserPermissions(userId)
      setUser(userData)
      setRedirect(true)
    }
    
    loadUserAndStoreData()
  }, [])
  
  
  
  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }
  
  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }
  
  const shouldOpenSidebar = isDesktop ? true : openSidebar
  
  return (
    <ScreenLoading loaded={shouldRedirect}>
      <UserContext.Provider value={{ user }}>
      <SnackbarProvider>


        <div
        className={clsx({
          [classes.root]: true,
          [classes.shiftContent]: isDesktop,
        })}
        >
          <Topbar onSidebarOpen={handleSidebarOpen} />
          <Sidebar
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          variant={isDesktop ? 'persistent' : 'temporary'}
          />
          <main className={classes.content}>
          {children}
            <Footer />
          </main>
        </div>
        </SnackbarProvider>
      </UserContext.Provider>
    </ScreenLoading>
    )
  }
  
  Main.propTypes = {
    children: PropTypes.node,
  }
  
  export default Main
  