import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import currency from 'currency.js'

const prefix = 'R$'

const parseValueString = value => {
  return (
    value
      .replace(`${prefix} `, '')
      .replace(',', '')
      .replace(/\./g, '') / 100
  )
}

const toCurrency = value => {
  return currency(value, {
    formatWithSymbol: true,
    separator: '.',
    decimal: ',',
    symbol: `${prefix} `,
  }).format()
}

const InputCurrency = props => {
  const { onChange, ...other } = props

  const [value, setValue] = useState(0)

  React.useEffect(() => {
    setValue(toCurrency(parseFloat(props.value || 0)))
  }, [props.value])

  const handleChange = event => {
    const value = event.target.value
    const parsedValue = parseValueString(value)

    if (parsedValue <= 0 || value === undefined) {
      onChange({ target: { value: 0.0, name: other.name } })
      return setValue(toCurrency(0))
    }
    if (!Number(parsedValue)) {
      return
    }

    onChange({ target: { value: parsedValue, name: other.name } })
    setValue(toCurrency(parsedValue))
  }

  return <TextField {...other} value={value} onChange={handleChange} />
}

export default InputCurrency
