import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import initWorkboxRefresh from '@loopmode/cra-workbox-refresh'
import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.register({
  onUpdate: registration =>
    initWorkboxRefresh(registration, {
      textContent: 'Nova versão disponível',
      className: 'workbox-refresh',
    }),
})
