import VMasker from 'vanilla-masker'

const parsePhoneApi = contact => {
  if (!contact) {
    return ''
  }

  const phone = contact.areaCode + contact.number

  if (phone.length > 10) {
    return VMasker.toPattern(phone, '(99) 99999-9999')
  }

  return VMasker.toPattern(phone, '(99) 9999-9999')
}

export default parsePhoneApi
