const statusLabel = status => {
  switch (status) {
    case 'issued':
      return 'Emitida'
    case 'created':
      return 'Aguardando Emissão NFE.io'
    case 'canceled':
      return 'Cancelada'
    case 'error':
      return 'Falha ao emitir'
    case 'pending_cancel':
      return 'Pendente de cancelamento'
    default:
      return 'Aguardando Emissão'
  }
}

export default statusLabel
