/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import * as service from 'service'

import { makeStyles } from '@material-ui/styles'

import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import InputIcon from '@material-ui/icons/Input'

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: '#546E7A',
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: '#546E7A',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  active: {
    backgroundColor: 'rgba(38, 50, 56, 0.08)',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  margin: {
    marginLeft: theme.spacing(7),
  },
}))

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
))

const SidebarNav = props => {
  const classes = useStyles()
  const { pages, className, onClose, ...rest } = props

  const handleLogout = () => {
    service.localStorage.logout()
  }

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map(page => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}
            onClick={onClose}
          >
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
        
          </Button>
        </ListItem>
      ))}
      <Hidden lgUp>
        <ListItem className={classes.item} disableGutters>
          <Button className={classes.button} onClick={handleLogout}>
            <div className={classes.icon}>
              <InputIcon />
            </div>
            Sair
          </Button>
        </ListItem>
      </Hidden>
    </List>
  )
}

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
}

export default SidebarNav
