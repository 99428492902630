import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment'
import { Send as SendIcon } from '@material-ui/icons'
import {recurrence} from '../../../../service'
import useSnackbar from 'hooks/useSnackbar'


import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton'

const AccountsTable = ({ size, loading, perPage, ...props }) => {
  const fakeData = Array.from(new Array(perPage))
  const accounts = props.accounts ? props.accounts : fakeData

  return (
    <PerfectScrollbar>
    <Table size="medium">
    <TableHead>
    <TableRow>
    <TableCell width="20%">Identificador</TableCell>
    <TableCell width="20%">Nome</TableCell>
    <TableCell width="20%">Email</TableCell>
    <TableCell width="10%">Tipo</TableCell>
    <TableCell width="20%">Data de Criação</TableCell>
    <TableCell width="10%" align="center">
    Status
    </TableCell>
    <TableCell width="10%" align="center"></TableCell>
    </TableRow>
    </TableHead>
    
    <TableBody>
    { accounts && accounts.map((account, i) => {
      if (account) {
        return (
          <ItemRow
          account={account}
          key={account.id}
          hover
          />
          )
        }
        
        return <SkeletonRow key={i} />
      })}
      </TableBody>
      
      
      </Table>
      </PerfectScrollbar>
      )
    }
    
    const ItemRow = ({ account, ...rest }) => {

      const snackbar = useSnackbar()
      const handleSend =  async accountId => {
        try {
          let response = await recurrence.users.transfer(accountId)
          let url = response.data.url
          url = url.replace('assinaturas.sandbox.yapay.com.br','recorrencia-sandbox.yapay.com.br');
          url = url.replace('assinaturas.yapay.com.br', 'recorrencia.yapay.com.br');
          url = url.replace('assinaturas.teste.yapay.net.br', 'recorrencia-teste.yapay.net.br');

          window.open( url + '/login/token/' + response.data.token, response.data.subdomain);
        } catch(error){
          snackbar.open({
            message: 'Erro ao tentar realizar acesso a conta selecionada',
            variant: 'error',
          })
        }
      }
    
      
      return (
        <TableRow {...rest}>
          <TableCell>{account.id}</TableCell>
          <TableCell>{account.name }</TableCell>
          <TableCell>{account.email }</TableCell>
          
          <TableCell>{account.subdomain}</TableCell>
          <TableCell>{moment(account.createdAt).utc().format('DD/MM/YYYY')}</TableCell>
          <TableCell align="center">
            {account.status}
          </TableCell>
          <TableCell>
            <SendIcon titleAccess="Acessar Conta" cursor="pointer" onClick={e => handleSend(account.id)} > </SendIcon>
          </TableCell>
        </TableRow>
        )
      }
      
      const SkeletonRow = () => (
        <TableRow>
          <TableCell>
            <Skeleton variant="rect" width="100%" height={21} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rect" width="100%" height={21} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rect" width="100%" height={21} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rect" width={20} height={21} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rect" width={20} height={21} />
          </TableCell>
        </TableRow>
        )
        
        export default AccountsTable
        