import humps from 'humps'

const configGatewayToArray = configGateway =>
  Object.keys(configGateway).reduce(
    (prevConfigs, tagConfig) => [
      ...prevConfigs,
      {
        tag: humps.decamelize(tagConfig),
        dataValue: configGateway[tagConfig],
      },
    ],
    [],
  )

export default configGatewayToArray
