const status = status => {
  if (!status) return ''

  switch (status) {
    case 'pending':
      return 'Pendente'

    case 'paid':
      return 'Pagamento Efetuado'

    case 'reversed':
      return 'Estornada'

    case 'awaiting_approval':
      return 'Aguardando Aprovação'

    case 'reproved_analysis':
      return 'Reprovada na Análise'

    default:
      return 'Aguardando Pagamento'
  }
}

export default status
