/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

const useFetch = (fn = () => {}, params, observable) => {
  const [response, setResponse] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setIsLoading(true)
    fn(params)
      .then(data => {
        setResponse(data)
        setIsLoading(false)
      })
      .catch(error => {
        setError(error)
      })
  }, observable)

  return { response, error, isLoading }
}

export default useFetch
