import humps from 'humps'

const configGatewayToObject = configGateway =>
  configGateway.reduce(
    (prevConfigs, config) => ({
      ...prevConfigs,
      [humps.camelize(config.tag)]: config.dataValue,
    }),
    {},
  )

export default configGatewayToObject
