import api from '../../api'
const recurrenceAPI = api.create('manager')

const paginate = async ({ ...params }) => {
  return await recurrenceAPI.get(`/api/v0/accounts/`, { params })
}

const total = async () => {
  return await recurrenceAPI.get(`/api/v0/accounts/`, { per_page: 1 })
}

export default {
  paginate,
  total
}