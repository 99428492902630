import axios from 'axios'
import humps from 'humps'
import { PROJECT_TAG } from './constants'
import { getBaseURL } from './env'
import * as service from 'service'

export const create = config => {
  let api = axios.create( { baseURL: getBaseURL(config) })

  api.interceptors.request.use(reqConfig => {
    const authToken = service.localStorage.getAuthToken()

    if (authToken) {
      reqConfig.headers.Authorization = `Bearer ${authToken}`
    }

    reqConfig.data = humps.decamelizeKeys(reqConfig.data)
    reqConfig.params = humps.decamelizeKeys(reqConfig.params)

    return reqConfig
  })

  api.interceptors.response.use(
    resp => humps.camelizeKeys(resp),
    error => {
      if (401 === error.response.status) {
        const signIn = getBaseURL('sign-in')
        service.localStorage.clear()
        window.location.assign(signIn + '/login/' + PROJECT_TAG)
        return Promise.reject(humps.camelizeKeys(error.response))
      }
      return Promise.reject(humps.camelizeKeys(error))
    },
  )

  return api
}

export default { create }
