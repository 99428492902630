const normalizeReceived = contractPlanEntries => {
  return contractPlanEntries && contractPlanEntries.length
    ? contractPlanEntries.map(contractPlanEntry => ({
        ...contractPlanEntry,
        quantity: contractPlanEntry.quantity,
        price:
          contractPlanEntry.price < 0
            ? contractPlanEntry.price * -1
            : contractPlanEntry.price,
      }))
    : [{ quantity: '', price: 0 }]
}

export default normalizeReceived
