import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import TotalAccount from './components/TotalAccount'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  scroll: {
    minWidth: '100%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  chipMargin: {
    marginRight: theme.spacing(1),
  },
  cards: {
    marginBottom: theme.spacing(4),
  },
}))

const Dashboard = () => {
  const classes = useStyles()

  return (
    <Container>
      <div className={classes.root}>
        <Grid
          container
          spacing={window.innerWidth > 800 ? 4 : 1}
          className={classes.cards}
        >
          <Grid container item xs={6} sm={4}>
            <TotalAccount></TotalAccount>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default Dashboard
