const status = status => {
  switch (status) {
    case 'active':
      return 'Ativo'
    case 'inactive':
      return 'Inativo'
    default:
      return 'Finalizado'
  }
}

export default status
