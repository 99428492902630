const styles = theme => ({
  box: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  spacingButton: {
    marginLeft: theme.spacing(2),
  },
  iconButton: {
    marginRight: theme.spacing(1),
  },
})

export default styles
