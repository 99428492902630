const downloadCsv = (data, fileName) => {
  var link = document.createElement('a')
  link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data)
  link.download = fileName
  document.body.append(link)
  link.click()
  link.remove()
}

export default downloadCsv
