import filterByEntity from './filterByEntity'
import hasQuantityDaysToDueDate from './hasQuantityDaysToDueDate'
import title from './title'
import description from './description'
import removeEmails from './removeEmails'

export default {
  filterByEntity,
  removeEmails,
  hasQuantityDaysToDueDate,
  title,
  description,
}
