export const getSubdomain = () => window.location.host.split('.')[0]

export const getBaseURL =  (project) => {
  console.info(project)
  return envsBaseUrl[project][env]
}

const env = process.env.REACT_APP_API || 'development'

const envsBaseUrl = {
  'yapay-login': {
    development: 'https://yapay-login-api-teste.yapay.net.br',
    test: 'https://yapay-login-api-teste.yapay.net.br',
    sandbox: 'https://yapay-login-api-sandbox.yapay.com.br',
    production: 'https://yapay-login-api.yapay.com.br',
  },
  'sign-in': {
    development: 'https://signin-teste.yapay.net.br',
    test: 'https://signin-teste.yapay.net.br',
    sandbox: 'https://signin-sandbox.yapay.com.br',
    production: 'https://signin.yapay.com.br',
  },
  manager: {
    development: `https://manager-api-local.yapay.net.br/`,
    test: `https://assinaturas-manager-api-teste.yapay.net.br/`,
    sandbox: `https://assinaturas-manager-api-sandbox.yapay.com.br/`,
    production: `https://assinaturas-manager-api.yapay.com.br/`,
  },
  recurrence: {
    development: `https://yapay.net.br/`,
    test: `https://recorrencia-api-teste.yapay.net.br/`,
    sandbox: `https://recorrencia-api-sandbox.yapay.com.br/`,
    production: `https://recorrencia-api.yapay.com.br/`,
  }

}

export default { getSubdomain, getBaseURL }
