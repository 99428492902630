import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import * as service from 'service'

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      height: '250px',
      justifyContent: 'flex-start',
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: '36px',
    color: '#bbb',
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
      fontSize: '42px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
      fontSize: '56px',
    },
  },
  title: {
    color: '#546E7A',
    fontSize: '12px',
    textAlign: 'center',
    lineHeight: 1.2,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
  },
  value: {
    justifyItems: 'baseline',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(1),
      fontSize: '24px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
      fontSize: '30px',
    },
  },
}))

const TotalAccount = () =>{
    const [count, setCount] = React.useState(0)
    const classes = useStyles()

    React.useEffect(() => {
        service.recurrence.accounts.total().then((response) => {
            setCount(response.data.pagination.totalCount)
        })
    })

    return (
        <Paper className={classes.card}>
      <div className={classes.content}>
        <AccountBoxIcon className={classes.icon}></AccountBoxIcon>
        <Typography className={classes.title}>Quantidade de Contas</Typography>
      </div>
      <Typography variant="h4" color="primary" className={classes.value}>
        { count || 0}
      </Typography>
    </Paper>)

}

export default TotalAccount