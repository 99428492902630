import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { routes } from 'Routes'
import * as service from 'service'

import AppBar from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import ExitToAppOutlined from '@material-ui/icons/ExitToAppOutlined'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    fontSize: 0,
    width: '90px',
    [theme.breakpoints.up('md')]: {
      width: '130px',
    },
  },
  logoutText: {
    marginLeft: theme.spacing(1),
    fontSize: '14px',
    fontWeight: '700',
  },
}))

const Topbar = props => {
  const { className, onSidebarOpen, history, ...rest } = props
  const classes = useStyles()

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to={routes.dashboard} className={classes.logo}>
          <img alt="Logo" src="/images/logos/yapay.svg" width="100%" />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton
            className={classes.signOutButton}
            onClick={service.localStorage.logout}
            color="inherit"
          >
            <ExitToAppOutlined />
            <span className={classes.logoutText}>Sair</span>
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
}

export default Topbar
