const statusColor = statusTag => {
  switch (statusTag) {
    case 'active':
      return '#21C712'

    case 'inactive':
      return '#777777'

    case 'canceled':
      return '#D33131'

    case 'pending':
      return '#FFC300'

    case 'overdue':
      return '#FA811B'

    case 'finished':
      return '#1CB0F6'

    default:
      return '#777777'
  }
}

export default statusColor
